import React from "react";

function LandingSection() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 pb-24 pt-10 mx-auto">
        <div className="text-center mb-20">
          <h1 className="pb-4 font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-red-600 text-4xl md:text-6xl">
            Services
          </h1>
          <p className="text-base leading-relaxed xl:w-3/5 lg:w-3/4 mx-auto text-gray-500s">
            8bit Systems specializes in the design and installation of all low
            voltage systems. We set ourselves apart by offering a complete
            integrated solution including Networking solutions, Surveillance
            System, security and fire alarm systems.
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex" />
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center  text-indigo-500 mb-5 flex-shrink-0">
              <svg
                className="text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 128 128"
              >
                <path
                  fill="#17ace8"
                  d="M28.345 30.863l68.68 39.654-5.977 10.353a8 8 0 01-10.928 2.928L25.296 52.144a8 8 0 01-2.928-10.928l5.977-10.353z"
                ></path>
                <path
                  fill="#0d70b2"
                  d="M48.254 12.382l77.993 45.031-12.98 22.482-84.921-49.031 8.98-15.554a8 8 0 0110.928-2.928z"
                ></path>
                <path
                  fill="#fedb41"
                  d="M91.051 80.871a8.127 8.127 0 01-.574.848c.022.013.04.028.061.04a12.98 12.98 0 0017.731-4.751l-11.241-6.49z"
                ></path>
                <path
                  fill="#00ddc1"
                  d="M104.207 59.675a4.98 4.98 0 106.8-1.823 4.98 4.98 0 00-6.8 1.823z"
                ></path>
                <path
                  fill="#e1e4fb"
                  d="M44.627 53.537a1.74 1.74 0 01-.873-.235L29.41 45.02a1.75 1.75 0 111.75-3.031L45.5 50.27a1.751 1.751 0 01-.877 3.267z"
                ></path>
                <path
                  fill="#0d70b2"
                  d="M1.75 57.373H7a6 6 0 016 6v47.317a6 6 0 01-6 6H1.75V57.373z"
                ></path>
                <path fill="#c5c9f7" d="M13 67.381h11.25v36H13z"></path>
                <path
                  fill="#e1e4fb"
                  d="M53.567 68.467l-5.051 8.612a8.034 8.034 0 01-6.9 3.953H24.25v12h17.364a20.078 20.078 0 0017.252-9.881l5.093-8.684z"
                ></path>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Surveillance System
              </h2>
              <p className="leading-relaxed text-base">
                8bit Systems specializes in both choosing and installing
                appropriate surveillance systems.8bit Systems specializes in
                both choosing and installing appropriate surveillance systems.
              </p>
              <a className="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0">
              <svg
                aria-hidden="true"
                className="text-gray-400 group-hover:text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                enableBackground="new 0 0 512 512"
                version="1.1"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
              >
                <path
                  fill="#455A64"
                  d="M373.333 322.532c-5.891 0-10.667-4.776-10.667-10.667V162.532c0-5.891 4.776-10.667 10.667-10.667S384 156.641 384 162.532v149.333c0 5.891-4.776 10.667-10.667 10.667z"
                ></path>
                <path
                  fill="#CFD8DC"
                  d="M53.333 301.177h405.333c29.455 0 53.333 23.878 53.333 53.333v42.667c0 29.455-23.878 53.333-53.333 53.333H53.333C23.878 450.511 0 426.632 0 397.177V354.51c0-29.455 23.878-53.333 53.333-53.333z"
                ></path>
                <g fill="#4CAF50">
                  <circle cx="74.667" cy="375.865" r="10.667"></circle>
                  <circle cx="138.667" cy="375.865" r="10.667"></circle>
                  <circle cx="202.667" cy="375.865" r="10.667"></circle>
                  <circle cx="266.667" cy="375.865" r="10.667"></circle>
                </g>
                <path
                  fill="#455A64"
                  d="M437.333 386.532H330.667c-5.891 0-10.667-4.776-10.667-10.667s4.776-10.667 10.667-10.667h106.667c5.891 0 10.667 4.776 10.667 10.667s-4.777 10.667-10.668 10.667z"
                ></path>
                <g fill="#2196F3">
                  <path d="M343.168 203.385a10.663 10.663 0 01-7.552-3.115c-20.831-20.825-20.835-54.594-.01-75.425l.01-.01c4.093-4.237 10.845-4.354 15.083-.262 4.237 4.093 4.354 10.845.262 15.083-.086.089-.173.176-.262.262-12.501 12.493-12.507 32.754-.014 45.255l.014.014c4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.021zM403.499 203.385a10.667 10.667 0 01-7.552-18.219c12.501-12.493 12.507-32.754.014-45.255l-.014-.014c-4.093-4.237-3.975-10.99.262-15.083a10.666 10.666 0 0114.821 0c20.831 20.825 20.835 54.594.01 75.425l-.01.01a10.667 10.667 0 01-7.531 3.136z"></path>
                  <path d="M312.981 233.551a10.663 10.663 0 01-7.552-3.115c-37.432-37.515-37.432-98.251 0-135.765a10.668 10.668 0 0115.083 15.083c-29.161 29.158-29.163 76.434-.005 105.595l.005.005c4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.022zM433.685 233.551a10.667 10.667 0 01-7.552-18.219c29.161-29.158 29.163-76.434.005-105.595l-.005-.005c-4.093-4.237-3.975-10.99.262-15.083a10.666 10.666 0 0114.821 0c37.432 37.515 37.432 98.251 0 135.765a10.67 10.67 0 01-7.531 3.137z"></path>
                  <path d="M282.816 263.716a10.663 10.663 0 01-7.552-3.115c-54.147-54.152-54.147-141.944 0-196.096 4.237-4.093 10.99-3.975 15.083.262 3.992 4.134 3.992 10.687 0 14.82-45.819 45.821-45.819 120.11 0 165.931 4.171 4.16 4.179 10.914.019 15.085a10.667 10.667 0 01-7.571 3.134l.021-.021zM463.851 263.716a10.667 10.667 0 01-7.552-18.219c45.819-45.821 45.819-120.11 0-165.931-4.093-4.237-3.976-10.99.262-15.083a10.666 10.666 0 0114.821 0c54.147 54.152 54.147 141.944 0 196.096a10.664 10.664 0 01-7.531 3.137z"></path>
                </g>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Networking Solutions
              </h2>
              <p className="leading-relaxed text-base">
                8bit Systems specializes in both choosing and installing
                appropriate surveillance systems. 8bit Systems specializes in
                both choosing and installing appropriate surveillance systems.
              </p>
              <a className="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0">
              <svg
                className=" text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#e8e8e8"
                  d="M481.095 109.199c17.069 0 30.905 13.837 30.905 30.905v293.602c0 17.069-13.837 30.905-30.905 30.905H30.905C13.837 464.612 0 450.775 0 433.706V140.105C0 123.036 13.837 109.2 30.905 109.2h450.19z"
                ></path>
                <path
                  fill="#c9c9c9"
                  d="M512 140.105v293.602c0 17.07-13.835 30.905-30.905 30.905H450.19c17.07 0 30.905-13.835 30.905-30.905V140.105c0-17.07-13.835-30.905-30.905-30.905h30.905c17.07-.001 30.905 13.835 30.905 30.905z"
                ></path>
                <path
                  fill="#b7b7b7"
                  d="M445.038 237.972H227.67c-11.379 0-20.604-9.225-20.604-20.604V67.992c0-11.379 9.225-20.604 20.604-20.604h217.368c11.379 0 20.604 9.225 20.604 20.604v149.376c0 11.379-9.225 20.604-20.604 20.604z"
                ></path>
                <path
                  fill="#bde0f2"
                  d="M429.586 207.066H243.123a5.15 5.15 0 01-5.151-5.151V83.445a5.15 5.15 0 015.151-5.151h186.463a5.15 5.15 0 015.151 5.151v118.471a5.152 5.152 0 01-5.151 5.15z"
                ></path>
                <path
                  fill="#b7b7b7"
                  d="M165.859 237.972H41.207a5.15 5.15 0 01-5.151-5.151v-82.414a5.15 5.15 0 015.151-5.151h124.652a5.15 5.15 0 015.151 5.151v82.414a5.15 5.15 0 01-5.151 5.151z"
                ></path>
                <path
                  fill="#fcd87e"
                  d="M165.859 304.934H41.207a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h124.652a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151z"
                ></path>
                <path
                  fill="#e8bb56"
                  d="M171.01 279.179v20.604a5.153 5.153 0 01-5.151 5.151h-30.905a5.153 5.153 0 005.151-5.151v-20.604a5.153 5.153 0 00-5.151-5.151h30.905a5.153 5.153 0 015.151 5.151z"
                ></path>
                <g fill="#7f7f93">
                  <path d="M253.94 304.934h-20.604a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.152 5.152 0 01-5.151 5.151zM315.75 304.934h-20.604a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151zM377.561 304.934h-20.604a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151zM439.372 304.934h-20.604a5.15 5.15 0 01-5.151-5.151v-20.604a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151zM253.94 366.744h-20.604a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.15zM315.75 366.744h-20.604a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.15zM377.561 366.744h-20.604a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.15zM439.372 366.744h-20.604a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.15zM253.94 428.555h-20.604a5.15 5.15 0 01-5.151-5.151V402.8a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.151 5.151 0 01-5.151 5.151zM315.75 428.555h-20.604a5.15 5.15 0 01-5.151-5.151V402.8a5.15 5.15 0 015.151-5.151h20.604a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.151z"></path>
                </g>
                <path
                  fill="#f49595"
                  d="M439.372 397.65h-82.414a5.15 5.15 0 00-5.151 5.151v20.604a5.15 5.15 0 005.151 5.151h82.414a5.15 5.15 0 005.151-5.151v-20.604a5.15 5.15 0 00-5.151-5.151z"
                ></path>
                <path
                  fill="#e86767"
                  d="M444.523 402.801v20.604a5.153 5.153 0 01-5.151 5.151h-30.905a5.153 5.153 0 005.151-5.151v-20.604a5.153 5.153 0 00-5.151-5.151h30.905a5.152 5.152 0 015.151 5.151z"
                ></path>
                <path
                  fill="#90d399"
                  d="M165.859 366.744H41.207a5.15 5.15 0 01-5.151-5.151V340.99a5.15 5.15 0 015.151-5.151h124.652a5.15 5.15 0 015.151 5.151v20.604a5.15 5.15 0 01-5.151 5.15z"
                ></path>
                <path
                  fill="#70ba70"
                  d="M171.01 340.99v20.604a5.153 5.153 0 01-5.151 5.151h-30.905a5.153 5.153 0 005.151-5.151V340.99a5.153 5.153 0 00-5.151-5.151h30.905a5.153 5.153 0 015.151 5.151z"
                ></path>
                <path
                  fill="#727272"
                  d="M70.567 214.793h-4.121a7.726 7.726 0 110-15.452h4.121a7.725 7.725 0 017.726 7.726 7.725 7.725 0 01-7.726 7.726zM70.567 183.887h-4.121a7.726 7.726 0 110-15.452h4.121c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726zM105.594 214.793h-4.121a7.726 7.726 0 110-15.452h4.121a7.727 7.727 0 010 15.452zM105.594 183.887h-4.121a7.726 7.726 0 110-15.452h4.121a7.726 7.726 0 110 15.452zM140.62 214.793h-4.121a7.726 7.726 0 110-15.452h4.121a7.727 7.727 0 010 15.452zM140.62 183.887h-4.121a7.726 7.726 0 110-15.452h4.121a7.726 7.726 0 110 15.452z"
                ></path>
                <path
                  fill="#83c8db"
                  d="M365.817 139.323c19.268 10.467 32.348 30.881 32.348 54.351v13.392H274.543v-13.392c0-22.693 12.229-42.529 30.456-53.279 0 0 21.912-10.592 31.355-10.42 9.443.171 29.463 9.348 29.463 9.348z"
                ></path>
                <path
                  fill="#56a9b7"
                  d="M398.165 193.674v13.392H367.26v-13.392c0-14.33-4.873-27.516-13.063-37.993a41.952 41.952 0 0016.205-13.598c16.73 11.053 27.763 30.04 27.763 51.591z"
                ></path>
                <path
                  fill="#fff"
                  d="M336.354 159.678c-23.043 0-41.722-18.68-41.722-41.722V78.294h83.445v39.662c-.001 23.042-18.68 41.722-41.723 41.722z"
                ></path>
                <path
                  fill="#e0e0e0"
                  d="M378.076 78.294v39.662a41.594 41.594 0 01-7.675 24.127 41.945 41.945 0 01-16.205 13.598 41.552 41.552 0 01-17.843 3.997 41.626 41.626 0 01-15.453-2.957c7.531-2.998 13.969-8.128 18.595-14.639a41.411 41.411 0 005.419-10.58 41.52 41.52 0 002.256-13.547V78.294z"
                ></path>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Intercom System
              </h2>
              <p className="leading-relaxed text-base">
                8bit Systems is proud to provide complete intercom services for
                all major brands and types of intercoms. We provide intercom
                installation, intercom repair, intercom system sales, intercom
                integration with security systems, smart intercom services, and
                much more. We serve all areas of Ajmer and Kishangarh City and
                many regions surrounding Ajmer, Kishangarh and Jalore.
              </p>
              <a className="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10  md:space-y-0 space-y-6 pt-20">
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center  text-indigo-500 mb-5 flex-shrink-0">
              <svg
                className="text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#f44336"
                  d="M12 18.75c0-1.437.812-2.687 2-3.316a4.503 4.503 0 013.533-4.322c-.551-2.063-1.502-3.881-2.384-5.548-.951-1.796-1.85-3.493-1.984-5.104a.5.5 0 00-.777-.373C7.25 3.554 7.329 8.551 7.717 10.914 6.115 9.478 5.487 7.386 5.48 7.36a.498.498 0 00-.33-.337.507.507 0 00-.463.086C4.578 7.197 2 9.319 2 14.5c0 4.416 3.29 7.5 8 7.5a9.83 9.83 0 002-.207z"
                ></path>
                <path
                  fill="#d43a2f"
                  d="M12 .358C7.254 3.81 7.339 8.612 7.717 10.914 6.115 9.478 5.487 7.386 5.48 7.36A.498.498 0 005.001 7a.508.508 0 00-.314.109C4.578 7.197 2 9.319 2 14.5c0 4.416 3.29 7.5 8 7.5a9.83 9.83 0 002-.207V18.75z"
                ></path>
                <path
                  fill="#607d8b"
                  d="M21.25 18.25a.75.75 0 01-.75-.75v-2a1 1 0 00-2 0v2a.75.75 0 01-1.5 0v-2c0-1.379 1.121-2.5 2.5-2.5s2.5 1.121 2.5 2.5v2a.75.75 0 01-.75.75z"
                ></path>
                <path
                  fill="#ffc107"
                  d="M22.25 17h-5.5c-.965 0-1.75.785-1.75 1.75v3.5c0 .965.785 1.75 1.75 1.75h5.5c.965 0 1.75-.785 1.75-1.75v-3.5c0-.965-.785-1.75-1.75-1.75z"
                ></path>
                <path
                  fill="#546d79"
                  d="M19.5 13a2.502 2.502 0 00-2.5 2.5V17h1.5v-1.5a1 1 0 011-1z"
                ></path>
                <path
                  fill="#dea806"
                  d="M19.5 17h-2.75c-.965 0-1.75.785-1.75 1.75v3.5c0 .965.785 1.75 1.75 1.75h2.75z"
                ></path>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Fire Alarm System
              </h2>
              <p className="leading-relaxed text-base">
                8bit Systems specializes in both choosing and installing
                appropriate surveillance systems.8bit Systems specializes in
                both choosing and installing appropriate surveillance systems.
              </p>
              <a className="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 500.238 500.238"
              >
                <path
                  fill="#fe7d43"
                  d="M462.702 179.884l-25.827-23.063-52.623 20.224-7.16 29.548-27.519 124.838 89.36 38.929 11.089-14.317c15.3-31.38 23.88-66.64 23.88-103.91 0-25.189-3.94-49.459-11.2-72.249z"
                ></path>
                <g>
                  <path
                    fill="#ee6161"
                    d="M370.7 316.284l-31.362 15.148-80.628 56.792 35.935 80.173 25.655 6.357v.01c57.33-21.32 103.462-64.83 130.042-119.38z"
                  ></path>
                  <path
                    fill="#c2a1d5"
                    d="M289.59 390.514l-17.107-11.991-102.233-15.526-49.118 78.911 22.609 28.476v.01c28.76 12.37 60.45 19.22 93.74 19.22 29.13 0 57.03-5.24 82.82-14.85v-.01z"
                  ></path>
                  <path
                    fill="#8ac9fe"
                    d="M174.961 360.978l-56.824-89.264-99.147 30.675v42.954c23.93 56.03 68.79 100.99 124.75 125.05v-.01l35.93-82.16z"
                  ></path>
                  <path
                    fill="#75cdd5"
                    d="M41.982 138.94L15.06 168.774h-.01C5.32 194.714 0 222.804 0 252.134c0 33.09 6.77 64.59 18.99 93.21H19l82.71-34.64 10.814-24.84 6.493-122.186z"
                  ></path>
                  <path
                    fill="#6cf5c2"
                    d="M181.916 38.284H134.08c-54.62 26.46-97.57 73.22-119.03 130.49h.01l83.75 32.02 54.76-27.722 72.418-65.143z"
                  ></path>
                  <path
                    fill="#fd6930"
                    d="M378.15 206.594l-4.387-19.506-54.061 13.706-2.912 24.95c2.77 8.29 4.26 17.17 4.26 26.39 0 12.76-2.86 24.84-7.97 35.65l1.17 19.907 35.323 18.797 21.126-10.204c9.36-19.4 14.61-41.16 14.61-64.15a147.73 147.73 0 00-7.159-45.54z"
                  ></path>
                  <path
                    fill="#e94444"
                    d="M313.08 287.784c-9.13 19.33-25.45 34.59-45.51 42.33l-7.711 14.505 16.073 41.16 13.658 4.735c35.63-13.42 64.7-40.19 81.11-74.23z"
                  ></path>
                  <path
                    fill="#a274bf"
                    d="M267.57 330.114c-9.33 3.61-19.48 5.59-30.09 5.59-11.36 0-22.18-2.26-32.05-6.38l-16.865 1.828-20.06 40.896 11.165 16.176c17.76 7.56 37.3 11.74 57.81 11.74 18.34 0 35.9-3.34 52.11-9.45z"
                  ></path>
                  <path
                    fill="#26a6fe"
                    d="M161 285.864l-13.195-9.541-43.061 14.922-3.034 19.459c15.03 34.81 43.05 62.68 77.96 77.52l25.76-58.9c-19.81-8.22-35.78-23.87-44.43-43.46z"
                  ></path>
                  <path
                    fill="#fee97d"
                    d="M351.33 43.684c-33.8-18.5-72.6-29.03-113.85-29.03-37.07 0-72.15 8.49-103.4 23.63l38.51 81 38.259 17.158 120.38 20.38 35.027-74.33z"
                  ></path>
                </g>
                <path
                  fill="#fec165"
                  d="M499.882 161.534c1.19 3.43-.71 7.16-4.17 8.24l-32.4 10.11-61.37 19.15c-13.72-42.64-43.5-78.07-82.24-99.15l31.18-56.2 16.5-29.74c1.74-3.15 5.74-4.26 8.85-2.45 57.36 33.25 101.73 86.42 123.65 150.04z"
                ></path>
                <path
                  fill="#2abcc8"
                  d="M153.91 252.134c0-9.97 1.74-19.53 4.95-28.39l-.738-34.835h-35.835L98.81 200.794c-5.92 15.99-9.16 33.29-9.16 51.34 0 20.81 4.3 40.61 12.06 58.57l59.29-24.84c-4.56-10.31-7.09-21.72-7.09-33.73z"
                ></path>
                <path
                  fill="#00f2a6"
                  d="M202.588 128.977l-29.999-9.694c-33.93 16.58-60.56 45.79-73.78 81.51l60.05 22.95c7.32-20.26 22.24-36.88 41.33-46.41v-.01l13.756-15.489z"
                ></path>
                <path
                  fill="#fedf30"
                  d="M307.84 122.084l-.013-.002c-20.919-11.339-44.878-17.778-70.347-17.778-23.28 0-45.3 5.38-64.89 14.98l27.6 58.05a83.3 83.3 0 0137.29-8.77c14.16 0 27.49 3.52 39.17 9.74l21.032 1.035 20.579-27.438z"
                ></path>
                <path
                  fill="#fdb441"
                  d="M401.942 199.034l-24.24 7.56-60.912 19.15c-6.81-20.49-21.686-37.633-40.486-47.623l31.088-56.037 12.31-22.2c38.74 21.08 68.52 56.51 82.24 99.15z"
                ></path>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Designing Works - Logo, Branding, Stationdary, Applications
              </h2>
              <p className="leading-relaxed text-base">
                8bit Systems specializes in both choosing and installing
                appropriate surveillance systems. 8bit Systems specializes in
                both choosing and installing appropriate surveillance systems.
              </p>
              <a className="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
          <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
            <div className="w-20 h-20 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="512"
                height="512"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#a9dbf5"
                  d="M481.081 438.5H30.919C13.843 438.5 0 424.657 0 407.581V137.102h512v270.479c0 17.076-13.843 30.919-30.919 30.919z"
                ></path>
                <path
                  fill="#88c3e0"
                  d="M481.095 137.102V438.5c17.069 0 30.905-13.849 30.905-30.932V137.102z"
                ></path>
                <g fill="#43809f">
                  <path d="M103.87 233.967a7.501 7.501 0 00-10.606 0l-46.431 46.431a7.5 7.5 0 000 10.607l45.816 45.816c1.464 1.464 3.384 2.196 5.303 2.196s3.839-.732 5.303-2.196a7.5 7.5 0 000-10.607l-40.513-40.513 41.127-41.127a7.5 7.5 0 00.001-10.607zM264.52 280.397l-46.431-46.431a7.501 7.501 0 00-10.606 10.607L248.61 285.7l-40.513 40.513a7.5 7.5 0 0010.606 10.607l45.817-45.816a7.502 7.502 0 000-10.607zM185.813 206.063a7.497 7.497 0 00-9.673 4.351l-54.902 144.638a7.5 7.5 0 1014.023 5.322l54.902-144.638a7.5 7.5 0 00-4.35-9.673z"></path>
                </g>
                <path
                  fill="#29cef6"
                  d="M386.318 234.23h-48.419c-8.534 0-15.453-6.918-15.453-15.453 0-8.534 6.918-15.453 15.453-15.453h48.419c8.534 0 15.453 6.918 15.453 15.453 0 8.534-6.919 15.453-15.453 15.453z"
                ></path>
                <path
                  fill="#ffc328"
                  d="M465.642 296.041H337.899c-8.534 0-15.453-6.918-15.453-15.453 0-8.534 6.918-15.453 15.453-15.453h127.742c8.534 0 15.453 6.918 15.453 15.453.001 8.534-6.918 15.453-15.452 15.453z"
                ></path>
                <path
                  fill="#f78e36"
                  d="M427.695 357.852h-89.796c-8.534 0-15.453-6.918-15.453-15.453 0-8.534 6.918-15.453 15.453-15.453h89.796c8.534 0 15.453 6.918 15.453 15.453 0 8.534-6.919 15.453-15.453 15.453z"
                ></path>
                <path
                  fill="#43809f"
                  d="M512 147.737H0v-43.318C0 87.343 13.843 73.5 30.919 73.5h450.162C498.157 73.5 512 87.343 512 104.419z"
                ></path>
                <path
                  fill="#3a7190"
                  d="M481.095 73.5v74.237H512v-43.305c0-17.083-13.837-30.932-30.905-30.932z"
                ></path>
                <circle
                  cx="49.455"
                  cy="111.126"
                  r="15.453"
                  fill="#29cef6"
                ></circle>
                <circle
                  cx="109.37"
                  cy="111.126"
                  r="15.453"
                  fill="#f3f3f3"
                ></circle>
                <circle
                  cx="169.285"
                  cy="111.126"
                  r="15.453"
                  fill="#f78e36"
                ></circle>
              </svg>
            </div>
            <div className="flex-grow">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                Website Design And Develop
              </h2>
              <p className="leading-relaxed text-base">
                8bit Systems specializes in both choosing and installing
                appropriate surveillance systems. 8bit Systems specializes in
                both choosing and installing appropriate surveillance systems.
              </p>
              <a className="mt-3 text-indigo-500 inline-flex items-center">
                Learn More
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <button className="flex mx-auto mt-16 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg transition-all ease-in-out duration-300 shadow-md hover:shadow-lg btn btn-primary btn-lg">
          Get Quote
        </button>
      </div>
    </section>
  );
}

export default LandingSection;
