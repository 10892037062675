import React, {useState} from "react";

// logos
import tenda from "../../svg/logos/tenda.png";
import seagate from "../../svg/logos/seagate.svg";
import cpplus from "../../svg/logos/cpplus.png";
import dlink from "../../svg/logos/dlink.svg";
import hikvision from "../../svg/logos/hikvision.svg";

function Logos() {
  const logosArr = [
    { src: hikvision, alt: "hikvision" },
    { src: cpplus, alt: "cp plus" },
    { src: tenda, alt: "tenda" },
    { src: seagate, alt: "seagate" },
    { src: dlink, alt: "dlink" },
  ];

  const [filter, setFilter] = useState("noFilter")

  const onMouseEnter = function(e) {
    setFilter('blackAndWhite');
    const current = e.currentTarget;
    current.style.filter = 'grayscale(0)'
    // current.style.filter = ''
    console.log("entered", current )
  } 
  const onMouseLeave = function(e) {
    setFilter('noFilter')
    const current = e.currentTarget;
    current.style.filter = ''
    // current.style.filter = ''
    console.log("entered", current )
  }

  return (
    <section className="container px-4 pb-24 pt-8 mx-auto">
      <h1 className=" font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-red-600 md:text-5xl">
        Our Quality Enhancers
      </h1>
      <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex " />
          </div>
      <div className="mt-12 grid grid-cols-3 gap-3 text-center lg:grid-cols-5">
        {logosArr.map((logo) => (
          <div
            className= {`lg:bg-gray-100 lg:rounded-md lg:py-4 lg:px-8 flex items-center justify-center gap-8 transition-all ease-in-out duration-150 ${filter}`}
            // style={{filter: `${filter}`}}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            key={logo.alt}
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="block object-contain h-12"
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default Logos;
