import React from "react";

import Surveillance from "../../images/survillance.png";

function Hero() {
  return (
    <section className="container px-4 pb-6 mx-auto">
      <div className="w-full mx-auto text-center md:w-11/12 xl:w-9/12">
        <h1
          style={{ lineHeight: "1.2" }}
          className="mb-6 text-5xl font-extrabold leading-none tracking-normal text-gray-900 md:text-6xl md:tracking-tight"
        >
          A complete{" "}
          <span className="block w-full bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-indigo-500 to-red-400 lg:inline">
            low voltage{" "}
            <span className="block w-full text-gray-900 lg:inline">
              solution for your
            </span>{" "}
            building and plant.
            <span style={{ lineHeight: "1.2" }}></span>
          </span>{" "}
        </h1>
        <p className="px-0 mb-6 text-lg text-gray-600 md:text-xl lg:px-24">
          8bit Systems provides the complete setup up of survilliance system
          (CCTV), networking system, intercom system, building automation, fire
          alarm, security systems, electrical monitoring, lighting controls, and
          other low voltage systems. We are proud to operate in{" "}
          <span className="font-semibold text-brandOrange-600">
            Kishangarh City, Kishangarh Industrial Area, Ajmer City, and nearby
            areas.
          </span>
        </p>
        <div className="mb-4 space-x-4 md:space-x-6 md:mb-8">
          <a
            className="inline-flex items-center justify-center w-full mb-2 px-4 py-2 font-medium rounded-md text-gray-50 bg-indigo-500 hover:bg-indigo-600 transition-all ease-in-out duration-300 shadow-md hover:shadow-lg btn btn-primary btn-lg sm:w-auto sm:mb-0"
            href="/services"
          >
            Services
          </a>
          <a
            className="inline-flex items-center justify-center w-full mb-2 px-4 py-2 font-medium rounded-md text-gray-50 bg-indigo-500 hover:bg-indigo-600 transition-all ease-in-out duration-300 shadow-md hover:shadow-lg btn btn-primary btn-lg sm:w-auto sm:mb-0"
            href="#"
          >
            Contact
          </a>
        </div>
      </div>
      <div className="w-full mx-auto mt-4 text-center md:w-10/12">
        <img
          src={Surveillance}
          height="487"
          width="987"
          alt="CCTV survillance illustration"
          className="w-full rounded-lg"
        />
      </div>
    </section>
  );
}

export default Hero;
