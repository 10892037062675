import React from "react";
import location from "../../svg/location.svg";

function AreaServed() {
  return (
    <section className="text-gray-600 body-font">
      <h1 className="font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-red-600 text-5xl md:text-6xl">
        Areas Served
      </h1>
      <div className="flex mt-6 justify-center">
        <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex" />
      </div>
      <div className="container px-5 pb-24 mx-auto flex flex-wrap items-center">
        <div className="hidden md:block lg:w-1/2 w-full0 mb-10 lg:mb-0 rounded-lg overflow-hidden">
          <img
            alt="feature"
            className="object-contain object-center h-full w-auto"
            src={location}
          />
        </div>
        <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="flex-grow">
              <h2 className="font-extrabold tracking-normal leading-normal bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-red-600 text-3xl md:text-4xl">
                Kishangarh
              </h2>
              <p className="leading-relaxed text-base">
                Blue bottle crucifix vinyl post-ironic four dollar toast vegan
                taxidermy. Gastropub indxgo juice poutine.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="flex-grow">
              <h2 className="font-extrabold tracking-normal  leading-normal text-3xl bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-red-600  md:text-4xl">
                Ajmer
              </h2>
              <p className="leading-relaxed text-base">
                Blue bottle crucifix vinyl post-ironic four dollar toast vegan
                taxidermy. Gastropub indxgo juice poutine.
              </p>
            </div>
          </div>
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="flex-grow">
              <h2 className="font-extrabold tracking-normal  leading-normal text-3xl bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-purple-600 to-red-600  md:text-4xl">
                Jalore
              </h2>
              <p className="leading-relaxed text-base">
                Blue bottle crucifix vinyl post-ironic four dollar toast vegan
                taxidermy. Gastropub indxgo juice poutine.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AreaServed;
